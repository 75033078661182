import React from 'react';

interface ComponentProps {
  children: any;
  className?: string;
}

const Container: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
}) => (
  <div className={`container mx-auto px-4 ${className}`}>
    <div className="lg:w-8/12 mx-auto">{children}</div>
  </div>
);

Container.defaultProps = {
  className: ``,
} as Partial<ComponentProps>;

export default Container;
